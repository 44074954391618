import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Typography,
  Divider,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Modal,
  IconButton,
  Skeleton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MenuSection from "../components/MenuSection";
import iconoCajero from "../images/icono-cajero.png";
import CloseIcon from "@mui/icons-material/Close";
import transferenciasAPI from "../api/transferencias";
import { verificarEmpleado } from "../helpers/verificarEmpleado";
import { generarComprobanteTransferencia, inicializarCanvas } from "../helpers/facturaUtils";
const TransferenciaPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [nombreCaja, setNombreCaja] = useState(JSON.parse(localStorage.getItem("puesto")).nombre);
  const [efectivo, setEfectivo] = useState("");
  const [totalTransferido, setTotalTransferido] = useState(0);
  const [transferencias, setTransferencias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [selectedOption, setSelectedOption] = useState("entregadoTesoreria"); // Default selected option
  const [TipoTransferencia, setTipoTransferencia] = useState(1); // Estado para TipoTransferencia
  const [nombreTransferencia, setNombreTransferencia] = useState("Tesoreria"); // Estado para TipoTransferencia

  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("es-AR");
  const formattedTime = currentDate.toLocaleTimeString("es-AR");
  const estacionData = JSON.parse(localStorage.getItem("estacionData"));
  const puesto = JSON.parse(localStorage.getItem("puesto"));

  useEffect(() => {
    const fetchTransferencias = async () => {
      setLoading(true);
      try {
        const ultimasTransferencias = await transferenciasAPI.getTransferencias();
        console.log("Transferencias obtenidas:", ultimasTransferencias.listadoTransferencias);

        setTransferencias(ultimasTransferencias.listadoTransferencias);
        setTotalTransferido(ultimasTransferencias.totalTrans);
      } catch (error) {
        console.error("Error al obtener las transferencias:", error);
        setTransferencias([]);
        setTotalTransferido(0);
      } finally {
        setLoading(false);
      }
    };

    fetchTransferencias();
  }, []);

  const handleGuardarTransferencia = async () => {
    if (isProcessing) return; // Evita múltiples envíos
    setIsProcessing(true); // Comienza el proceso de transferencia
    setLoading(true);

    try {
      const resultadoVerificacion = await verificarEmpleado(password);

      if (!resultadoVerificacion.success) {
        setPasswordError(true);
        setIsProcessing(false); // Resetea el estado de carga si hay un error
        return;
      }

      const efectivoValue = parseFloat(efectivo) || 0;

      // Realizar la transferencia
      const respuestaTransferencia = await transferenciasAPI.realizarTransferencia(
        resultadoVerificacion.empleado.idEmpleado,
        efectivoValue,
        TipoTransferencia, // Pasar el valor de TipoTransferencia a la función
      );

      // Verificar si la respuesta indica éxito
      if (respuestaTransferencia === "Transferencia realizada exitosamente.") {
        // Obtener las últimas transferencias desde la API
        const ultimasTransferencias = await transferenciasAPI.getTransferencias();
        setTransferencias(ultimasTransferencias.listadoTransferencias);
        setTotalTransferido(ultimasTransferencias.totalTrans);

        // Inicializar el canvas y contexto antes de usarlo
        const context = inicializarCanvas(550, 650); // Ancho 550 y alto 100
        let y = 30; // Valor inicial de y para dibujar en el canvas

        // Generar el comprobante de transferencia
        generarComprobanteTransferencia(
          context,
          y,
          estacionData.data,
          formattedDate,
          formattedTime,
          puesto,
          nombreTransferencia,
          efectivoValue,
          resultadoVerificacion.empleado.nombreEmpleado,
        );

        const dataURL = context.canvas.toDataURL("image/png");
        const base64Data = dataURL.replace(/^data:image\/png;base64,/, "");
        console.log(base64Data);

        // Imprimir solo si la transferencia fue exitosa
        window.MyInterface.imprimirFacturaAfip(base64Data);

        // Limpiar el estado después de la transferencia
        setEfectivo("");
        setSnackbarOpen(true);
        setPasswordModalOpen(false);
      } else {
        console.error("Error en la transferencia:", respuestaTransferencia);
      }
    } catch (error) {
      console.error("Error al realizar la transferencia:", error); // Capturar y loguear cualquier error
    } finally {
      setLoading(false); // Termina la carga
      setIsProcessing(false); // Finaliza el proceso
      setPasswordModalOpen(false); // Cerrar el modal de contraseña al terminar
    }
  };

  const handlePasswordSubmit = () => {
    handleGuardarTransferencia();
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => setModalOpen(false);
  const handleCloseSnackbar = () => setSnackbarOpen(false);

  const openPasswordModal = () => {
    setPassword("");
    setPasswordError(false);
    setPasswordModalOpen(true);
  };

  const handleTipoTransferenciaChange = event => {
    const value = event.target.value;

    switch (value) {
      case "entregadoTesoreria":
        setTipoTransferencia(1);
        setNombreTransferencia("Tesoreria");
        break;
      case "recibidoTesoreria":
        setTipoTransferencia(2);
        setNombreTransferencia("");
        break;
      case "entregadoEmpresa":
        setTipoTransferencia(3);
        setNombreTransferencia("Empresa");
        break;
      default:
        setNombreTransferencia("Tesoreria");
        setTipoTransferencia(1);
    }

    setSelectedOption(value);
  };

  return (
    <>
      <MenuSection />

      <Box sx={{ width: "90%", height: "78vh" }}>
        <Typography sx={{ fontWeight: "600" }}>Transferencias de valores durante el turno</Typography>
        <Divider />
        <Box sx={{ width: "90%", margin: "0 auto", display: "flex", marginTop: "15px", flexDirection: "column" }}>
          <Box sx={{ flex: "1 1 33%", paddingRight: 0 }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>CAJA: {nombreCaja}</Typography>

              <Typography sx={{ fontWeight: 600, margin: "15px 0 0px 0", fontSize: "14px" }}>Datos de la transferencia</Typography>

              <RadioGroup value={selectedOption} onChange={handleTipoTransferenciaChange}>
                <FormControlLabel
                  value="entregadoTesoreria"
                  control={<Radio sx={{ color: theme.bandera.colorSecundario, "&.Mui-checked": { color: theme.bandera.colorSecundario } }} />}
                  label="Entregado a tesorería"
                />
                <FormControlLabel
                  value="recibidoTesoreria"
                  control={<Radio sx={{ color: theme.bandera.colorSecundario, "&.Mui-checked": { color: theme.bandera.colorSecundario } }} />}
                  label="Recibido de tesorería"
                />
                <FormControlLabel
                  value="entregadoEmpresa"
                  control={<Radio sx={{ color: theme.bandera.colorSecundario, "&.Mui-checked": { color: theme.bandera.colorSecundario } }} />}
                  label="Entregado a empresa recaudadora"
                />
              </RadioGroup>

              <Typography sx={{ marginBottom: "15px", marginTop: "10px", fontWeight: 600, fontSize: "14px" }}>Detalle de los valores transferidos</Typography>
              <TextField
                label="Efectivo"
                value={efectivo}
                onChange={e => setEfectivo(e.target.value)}
                fullWidth
                type="number"
                sx={{ marginBottom: "15px" }}
                InputProps={{
                  style: {
                    padding: "0 14px",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <Button
                variant="outlined"
                sx={{ color: "#7c7d81", fontWeight: "600", margin: "0 auto", borderColor: theme.bandera.colorSecundario }}
                onClick={openPasswordModal}
                startIcon={<img src={iconoCajero} alt="Icon" style={{ width: 24, height: 24 }} />}
                disabled={efectivo === "" || isNaN(Number(efectivo)) || Number(efectivo) <= 0}
              >
                Cajero
              </Button>
            </Box>
          </Box>

          <Box sx={{ flex: "1 1 67%", paddingLeft: 0, marginTop: "15px" }}>
            {loading ? (
              <Skeleton animation="wave" width="100%" height={80} />
            ) : (
              <TextField
                label="Total transferido"
                value={`$${totalTransferido.toFixed(2)}`}
                InputProps={{
                  readOnly: true,
                  style: {
                    padding: "0 14px",
                    width: "100%",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                sx={{ marginTop: theme.spacing(2), position: "sticky", bottom: theme.spacing(2), backgroundColor: "#fff", zIndex: 1 }}
              />
            )}

            <Button
              variant="outlined"
              onClick={handleOpenModal}
              sx={{
                marginBottom: theme.spacing(2),
                marginTop: "15px",
                color: theme.bandera.colorSecundario,
                borderColor: theme.bandera.colorSecundario,
              }}
            >
              Ver Transferencias Realizadas
            </Button>

            <Modal open={modalOpen} onClose={handleCloseModal} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Box sx={{ width: "100%", maxWidth: 600, px: 4, bgcolor: "background.paper", borderRadius: 1, maxHeight: "80vh", overflowY: "auto" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    bgcolor: "background.paper",
                    p: 2,
                  }}
                >
                  <Typography sx={{ fontWeight: 600 }}>Transferencias realizadas</Typography>
                  <IconButton onClick={handleCloseModal}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ my: 2 }} />
                {loading ? (
                  <Skeleton animation="wave" width="100%" height={80} />
                ) : (
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        {transferencias
                          .slice()
                          .reverse()
                          .map((transferencia, index) => (
                            <TableRow key={index}>
                              <TableCell>{transferencia}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            </Modal>

            <Modal
              open={passwordModalOpen}
              onClose={() => setPasswordModalOpen(false)}
              sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
              <Box sx={{ width: 300, p: 4, bgcolor: "background.paper", borderRadius: 1, textAlign: "center" }}>
                <Typography sx={{ mb: 2 }}>Ingrese su contraseña</Typography>
                <TextField
                  label="Contraseña"
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  fullWidth
                  error={passwordError}
                  helperText={passwordError && "Contraseña incorrecta"}
                />
                <Button
                  variant="contained"
                  onClick={handlePasswordSubmit}
                  disabled={isProcessing}
                  sx={{
                    mt: 2,
                    backgroundColor: theme.bandera.colorSecundario,
                    ":hover": {
                      backgroundColor: theme.bandera.colorSecundario,
                    },
                    "&.Mui-disabled": {
                      color: isProcessing ? "white" : "white",
                      backgroundColor: theme.bandera.colorSecundario,
                    },
                  }}
                >
                  {isProcessing ? "Procesando..." : "Confirmar"}
                </Button>
              </Box>
            </Modal>

            <Modal
              open={snackbarOpen}
              onClose={handleCloseSnackbar}
              sx={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "rgba(0, 0, 0, 0.1)" }}
            >
              <Box
                sx={{
                  width: 300,
                  p: 4,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  textAlign: "center",
                  boxShadow: 24,
                }}
              >
                <CheckCircleIcon sx={{ fontSize: 60, color: "green" }} />
                <Typography sx={{ mt: 2, fontWeight: 600, fontSize: 20 }}>Transferencia realizada con éxito</Typography>
              </Box>
            </Modal>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TransferenciaPage;
