import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  IconButton,
  CircularProgress,
  Button,
  Alert,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Link, useNavigate } from "react-router-dom";
import MenuSection from "../components/MenuSection";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import afip from "../api/afip";
import categoriasIVA from "../api/categoriasIVA";
import condicionesVenta from "../api/condicionesVenta";
import { Grid } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Percepciones from "../api/percepciones";

const AltaCliente = () => {
  const [cuit, setCuit] = useState("");
  const [razonSocial, setRazonSocial] = useState("");
  const [provincia, setProvincia] = useState("");
  const [localidad, setLocalidad] = useState("");
  const [codigoPostal, setCodigoPostal] = useState("");
  const [idLocalidad, setIdLocalidad] = useState("");
  const [calle, setCalle] = useState("");
  const [catIva, setCatIva] = useState("");
  const [condVenta, setCondVenta] = useState("");
  const [modalWidth, setModalWidth] = useState("500px");
  const [isLoading, setIsLoading] = useState(false);
  const [condVentasList, setCondVentasList] = useState([]);
  const [categoriaIvaList, setCategoriaIvaList] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();
  const validateForm = () => {
    return cuit.trim() !== "" && razonSocial !== "" && codigoPostal !== "" && calle !== "" && catIva !== "" && condVenta !== "";
  };

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setIsLoading(false);

    setOpenConfirmDialog(false);
  };

  const handleConfirm = () => {
    setOpenConfirmDialog(false);
    const esPlaya = localStorage.getItem("solicitarAforadores");
    if (esPlaya == "true") {
      navigate("/dashboard");
    } else {
      navigate("/dashboardmini");
    }
  };

  const handleCancel = () => {
    setOpenConfirmDialog(false);
    navigate("/clientessearchpage");
  };

  useEffect(() => {
    const fetchCategoriasIVA = async () => {
      try {
        const listadoCatIVA = await categoriasIVA.getCategoriasIVA();
        const formatoCatIva = listadoCatIVA.map(condicion => ({
          value: condicion.idCategoriaIVA.toString(),
          label: condicion.descripcionCategoriaIVA,
        }));
        setCategoriaIvaList(formatoCatIva);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    const fetchCondVenta = async () => {
      try {
        const listadoCondVenta = await condicionesVenta.getCondVenta();
        const formatoCondVentas = listadoCondVenta.map(condicion => ({
          value: condicion.idCondicionVenta.toString(),
          label: condicion.descripcionCondicionVenta,
        }));
        setCondVentasList(formatoCondVentas);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchCategoriasIVA();
    fetchCondVenta();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 700) {
        setModalWidth("360px");
      } else {
        setModalWidth("900px");
      }
    };

    handleResize();
  }, []);

  const handleSearch = async cuit => {
    setIsLoading(true);
    try {
      const userAfip = await afip.getClienteAfip(cuit);
      console.log(userAfip);
      if (!userAfip) {
        setAlertOpen(true);
      } else {
        let newRazonSocial = userAfip.razonSocial ? userAfip.razonSocial.slice(0, 40) : "";
        if (!newRazonSocial) {
          newRazonSocial = `${userAfip.apellido}, ${userAfip.nombre}`;
        }
        setRazonSocial(newRazonSocial);
        setCodigoPostal(userAfip.codPostal);
        setLocalidad(userAfip.localidad);
        setIdLocalidad(userAfip.idLocalidadSegunCaldenOil);
        setProvincia(userAfip.descripcionProvincia);

        // Truncar la calle a 40 caracteres si existe
        const truncatedCalle = userAfip.direccion ? userAfip.direccion.slice(0, 40) : "";
        setCalle(truncatedCalle);

        setCondVenta(1);
        setCatIva(userAfip.idCategoriaIVASegunCaldenOil);
      }
    } catch (error) {
      setRazonSocial("");
      setCalle("");
      setIdLocalidad("");
      setCodigoPostal("");
      setCondVenta("");
      setCatIva("");
      console.error("Error al buscar cliente:", error);
      setAlertOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const formatCuit = value => {
    const newValue = value.replace(/\D/g, "");
    return newValue.replace(/^(\d{2})(\d{8})(\d{1})$/, "$1-$2-$3");
  };

  const handleAgregar = async () => {
    const clienteObj = {};
    clienteObj.razonSocial = razonSocial;
    clienteObj.calle = calle;
    clienteObj.localidad = {
      idLocalidad: idLocalidad,
      nombreLocalidad: localidad,
      provincia: {
        nombreProvincia: provincia,
      },
    };

    clienteObj.numeroDocumento = formatCuit(cuit);
    if (catIva === 4) {
      clienteObj.categoriaIVA = {
        descripcionCategoriaIVA: "EXENTO",
        abreviaturaCategoriaIVA: "EX",
        codigoAFIPCategoriaIVA: "4",
      };
    } else if (catIva === 3) {
      clienteObj.categoriaIVA = {
        descripcionCategoriaIVA: "MONOTRIBUTO",
        abreviaturaCategoriaIVA: "MO",
        codigoAFIPCategoriaIVA: "3",
      };
    } else {
      clienteObj.categoriaIVA = {
        descripcionCategoriaIVA: "RESPONSABLE INSCRIPTO",
        abreviaturaCategoriaIVA: "RI",
        codigoAFIPCategoriaIVA: "1",
      };
    }

    const selectedCondVenta = condVentasList.find(option => option.value === condVenta);
    clienteObj.condicionVenta = {
      descripcionCondicionVenta: selectedCondVenta ? selectedCondVenta.label : "",
      condicionVentaContado: true,
    };
    console.log(clienteObj);
    localStorage.setItem("cliente", JSON.stringify(clienteObj));
    const numeroDocumento = clienteObj.numeroDocumento.replace(/-/g, "");
    setIsLoading(true);

    try {
      const percepciones = await Percepciones.getPercepciones(numeroDocumento);
      console.log(numeroDocumento);
      console.log(percepciones);
      localStorage.setItem("percepciones", percepciones);
      if (percepciones == true) {
        handleOpenConfirmDialog();
      } else if (validateForm()) {
        const esPlaya = localStorage.getItem("solicitarAforadores");
        if (esPlaya == "true") {
          navigate("/dashboard");
        } else {
          navigate("/dashboardmini");
        }
      }
    } catch (error) {
      console.error("Error al obtener percepciones:", error);
    }
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  return (
    <>
      <MenuSection />

      <Box
        sx={{
          position: "absolute",
          width: modalWidth,
          top: window.innerWidth < 700 ? "49%" : "54%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          p: 4,
          borderRadius: "8px",
          height: window.innerWidth < 700 ? "80vh" : "90%",
        }}
      >
        <TextField
          label="CUIT"
          value={formatCuit(cuit)}
          variant="outlined"
          fullWidth
          sx={{ mb: 4 }}
          onChange={e => setCuit(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => handleSearch(cuit)}>
                <SearchIcon sx={{ height: "2rem", width: "2rem" }} />
              </IconButton>
            ),
          }}
          InputLabelProps={{
            style: { fontSize: "0.85rem", color: "gray" },
          }}
        />
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "8rem",
              height: window.innerWidth < 700 ? 360 : 290,
            }}
          >
            <CircularProgress sx={{ color: theme.bandera.colorSecundario }} />
          </div>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Razón Social"
                  value={razonSocial || ""}
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 0.5 }}
                  InputLabelProps={{
                    style: { fontSize: "0.85rem", color: "gray" },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Código Postal"
                      value={codigoPostal || ""}
                      variant="outlined"
                      fullWidth
                      sx={{ mb: 0.5 }}
                      InputLabelProps={{
                        style: { fontSize: "0.85rem", color: "gray" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Calle"
                      value={calle || ""}
                      variant="outlined"
                      fullWidth
                      sx={{ mb: 0.5 }}
                      InputLabelProps={{
                        style: { fontSize: "0.85rem", color: "gray" },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Categoría IVA"
                  value={catIva}
                  select
                  disabled
                  defaultValue="RESPONSABLE INSCRIPTO"
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 0.5 }}
                  InputLabelProps={{
                    style: { fontSize: "0.85rem", color: "gray" },
                  }}
                  onChange={e => setCatIva(e.target.value)}
                >
                  {categoriaIvaList.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Condición Venta"
                  value={condVenta}
                  select
                  disabled
                  defaultValue="CONTADO"
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 0.5 }}
                  InputLabelProps={{
                    style: { fontSize: "0.85rem", color: "gray" },
                  }}
                  onChange={e => setCondVenta(e.target.value)}
                >
                  {condVentasList.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end", // Alinea el contenido a la derecha
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  boxShadow: 0,
                  fontWeight: "bold",
                  fontSize: 17,
                  marginTop: "19px",
                  backgroundColor: theme.bandera.colorSecundario,
                  ":hover": {
                    backgroundColor: theme.bandera.colorSecundario,
                  },
                }}
                disabled={!validateForm()}
                onClick={handleAgregar}
              >
                AGREGAR
              </Button>
            </Box>
          </>
        )}
      </Box>

      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: theme.palette.background.default,
            boxShadow: theme.shadows[5],
            borderRadius: theme.shape.borderRadius,
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{"Cliente con percepciones"}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "1rem" }}>El cliente percibe percepciones ¿desea continuar?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            <Typography variant="body1" style={{ fontSize: "16px", fontWeight: "bold" }}>
              Cancelar
            </Typography>
          </Button>

          <Button onClick={handleConfirm} color="primary" autoFocus>
            <Typography variant="body1" style={{ fontSize: "16px", fontWeight: "bold" }}>
              Aceptar
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={alertOpen}
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: theme.palette.background.default,
            boxShadow: theme.shadows[5],
            borderRadius: theme.shape.borderRadius,
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{"AFIP"}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "1.25rem" }}>La clave ingresada no es una CUIT ni una CDI</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlert} color="primary" autoFocus>
            <Typography variant="body1" style={{ fontSize: "16px", fontWeight: "bold" }}>
              Aceptar
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AltaCliente;
